import { Heading, Text } from "@chakra-ui/react";

export const PageHeaderContent = ({
  headerTitle,
  headerSubtitle,
  breakText,
}) => {
  return (
    <>
      <Heading
        fontWeight={"normal"}
        fontSize={{
          base: "2xl",
          md: "3xl",
          lg: "4xl",
          xl: "4xl",
          "2xl": "5xl",
        }}
      >
        {headerTitle}
        <Text display={{ base: "inline-block", md: "block" }}>{breakText}</Text>
      </Heading>
      <Text
        color={"gray.400"}
        fontSize={{ base: "sm", lg: "inherit", xl: "lg", "2xl": "2xl" }}
      >
        {headerSubtitle}
      </Text>
    </>
  );
};
