import { createBrowserRouter } from "react-router-dom";
import Main from "../layout/Main";
import { HRMSKeyForm } from "../pages/HRMSKeyForm/HRMSKeyForm";
import { FormSubmitSuccess } from "../pages/FormSubmitSuccess/FormSubmitSuccess";
import NotFound from "../pages/NotFound/NotFound";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
export const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    errorElement: <NotFound />,
    children: [
      {
        path: "/",
        element: (
          <PrivateRoute>
            <HRMSKeyForm />
          </PrivateRoute>
        ),
      },
      {
        path: "/hrmskey",
        element: (
          <PrivateRoute>
            <HRMSKeyForm />
          </PrivateRoute>
        ),
      },
      {
        path: "/submit-success",
        element: (
          <PrivateRoute>
            <FormSubmitSuccess />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: "/not-found",
    element: <NotFound />,
  },
]);
