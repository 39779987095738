import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
export const ApiKeyAccordion = () => {
  return (
    <>
      <Accordion allowMultiple>
        <AccordionItem border={"none"} pl={0}>
          <h2>
            <AccordionButton pl={0} _hover={{ bg: "transparent" }}>
              <Box as="span" textAlign="left" color={"gray.500"} mr={1}>
                How do I get API Key?
              </Box>
              <AccordionIcon color={"gray.500"} w={6} h={6} />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} pl={0} color={"gray.500"}>
            To generate an API key, you should log in and click on your name in
            the upper right-hand corner of any page on BambooHR to get to the
            user context menu. If you have sufficient permissions, there will be
            an "API Keys" option in that menu to go to the page.
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};
