import { Navigate, useLocation, useSearchParams } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const [searchParams] = useSearchParams();
  const hrms = searchParams.get("hrms");
  const location = useLocation();

  // form success page private route condition
  const isSubmitSuccess = location?.state?.from === "/hrmsKey";
  if (isSubmitSuccess) {
    return children;
  } else if (hrms) {
    return children;
  }
  // window.location.href = "https://keeper.co.in/";
  return <Navigate to="/not-found" />;
};

export default PrivateRoute;
