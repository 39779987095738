import { Box, Flex, Image } from "@chakra-ui/react";
import logo from "../../assets/logo3.png";
export const PageHeader = ({ children }) => {
  return (
    <>
      <Flex
        justify={"left"}
        alignItems={"center"}
        gap={{ base: 5, md: 5, lg: 8 }}
      >
        <Box w={{ base: "15%", md: "6%", lg: "6%", xl: "4%", "2xl": "4%" }}>
          <Image src={logo} w={"full"} h={"full"} />
        </Box>
        <Box>{children}</Box>
      </Flex>
    </>
  );
};
