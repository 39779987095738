import { Box, Heading, Text, Button, Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <Flex h={"100vh"} justify={"center"} align={"center"}>
      <Box textAlign="center" py={10} px={6}>
        <Heading
          display="inline-block"
          as="h2"
          size="2xl"
          bg="primary"
          backgroundClip="text"
        >
          404
        </Heading>
        <Text fontSize="18px" mt={3} mb={2} fontWeight={"semibold"}>
          Page Not Found
        </Text>
        <Text color={"gray.500"} mb={6}>
          The page you're looking for does not seem to exist
        </Text>

        <Button
          bg="primary"
          color="white"
          variant="solid"
          _hover={{ bg: "primary" }}
        >
          <Link to={"/"}>Go to Home</Link>
        </Button>
      </Box>
    </Flex>
  );
}
