import {
  Flex,
  Box,
  Heading,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Image,
  InputLeftAddon,
  InputRightAddon,
  Text,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import kekaLogo from "../../assets/brand_1.png";
import greythrLogo from "../../assets/brand_4.png";
import darwinboxLogo from "../../assets/brand_2.png";
import bambooHRLogo from "../../assets/bamboohr.svg";
import { SubdomainAccordion } from "../../components/CustomAccordion/SubdomainAccordion";
import { ApiKeyAccordion } from "../../components/CustomAccordion/ApiKeyAccordion";
import "./HRMSKeyForm.css";
import onboardingImage from "../../assets/onboarding_keeper.png";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { PageHeaderContent } from "../../components/PageHeader/PageHeaderContent";
import { useState } from "react";
import axios from "axios";
const TELEGRAM_BOT_API_URL =
  "https://api.telegram.org/bot5890160332:AAETX4zOeQ5qY1aPVxFVSVaz6P6dkpeoq78/sendMessage";

const imageSources = {
  keka: kekaLogo,
  greythr: greythrLogo,
  bamboohr: bambooHRLogo,
  darwinbox: darwinboxLogo,
};

const formFieldConditions = {
  IsUsernameFieldShow: (hrms) => hrms === "greythr",
  IsUsernamePasswordFieldShow: (hrms) => hrms === "darwinbox",
  isDataSetFieldShow: (hrms) => hrms === "darwinbox",
  isClientIdSecretShow: (hrms) => hrms === "keka",
};

export const HRMSKeyForm = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState("initial");
  const hrms = searchParams.get("hrms");
  const companyName = searchParams.get("companyName");

  const { register, reset, handleSubmit } = useForm();
  const onSubmit = async (data) => {
    setState("submitting");
    const payload = {
      chat_id: "-908107678",
      text: data,
    };
    try {
      const response = await axios.post(TELEGRAM_BOT_API_URL, payload);
      if (response.data.ok) {
        setState("success");
        reset();
        navigate("/submit-success", { state: { from: location.pathname } });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Get HRMS image by HRMS name
  const imageSource = imageSources[hrms?.toLowerCase()] || null;

  // Field display conditions
  const fieldConditions = Object.entries(formFieldConditions).reduce(
    (conditions, [field, conditionFn]) => {
      conditions[field] = conditionFn(hrms?.toLowerCase());
      return conditions;
    },
    {}
  );
  // hints show condition
  const apiKeyHintsShow = hrms === "bamboohr";

  return (
    <>
      <Box
        maxW="98%"
        py={{ base: 5, md: 10 }}
        px={{ base: 5, md: 10 }}
        overflow="hidden"
      >
        <PageHeader>
          <PageHeaderContent
            headerTitle={"Welcome aboard!"}
            headerSubtitle={"Fill the form below and you’ll be all set."}
          />
        </PageHeader>
        <Flex
          justify={"center"}
          align={"center"}
          h={{ base: "full", md: "full", lg: "100vh" }}
        >
          <Box m={{ sm: 0, md: 16, lg: 10 }} p={{ sm: 0, md: 5, lg: 16 }}>
            <Box p={4}>
              <Flex
                gap={{ base: 10, sm: 3, md: 5, lg: 20, "2xl": "28" }}
                justify={"space-around"}
                alignItems={"center"}
                w={"full"}
                flexDirection={{ base: "column", md: "column", lg: "row" }}
              >
                <Box
                  w={{ base: "full", md: "full", lg: "50%", "2xl": "55%" }}
                  alignSelf={"center"}
                  mt={{ base: 5, md: 0 }}
                >
                  <Box>
                    <Image src={onboardingImage} objectFit={"cover"} />
                  </Box>
                </Box>
                <Box
                  w={{ base: "full", md: "full", lg: "40%", "2xl": "45%" }}
                  alignSelf={"center"}
                >
                  <Box w={"full"} id="scrollbar">
                    <Flex justify={"space-between"} alignItems={"center"}>
                      <Heading
                        textColor={"primary"}
                        textTransform={"capitalize"}
                      >
                        {companyName}
                      </Heading>
                      {imageSource && <Image src={imageSource} w={"28"} />}
                    </Flex>

                    <Box mt={5} color="gray.700">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <VStack spacing={5}>
                          <FormControl id="subdomain">
                            <SubdomainAccordion hrms={hrms} />
                            <FormLabel>Enter SubDomain</FormLabel>
                            <InputGroup size="md" borderColor="primary">
                              <InputLeftAddon children="https://" />
                              <Input
                                placeholder="Enter SubDomain"
                                focusBorderColor="primary"
                                _hover={{ borderColor: "primary" }}
                                {...register("subDomain", {
                                  required: true,
                                })}
                              />
                              <InputRightAddon children=".com" />
                            </InputGroup>
                          </FormControl>
                          {fieldConditions?.IsUsernameFieldShow && (
                            <FormControl id="username">
                              <FormLabel>
                                Enter your{" "}
                                <Text
                                  display={"inline-block"}
                                  textTransform={"capitalize"}
                                >
                                  {hrms}
                                </Text>{" "}
                                API username
                              </FormLabel>
                              <InputGroup borderColor="primary">
                                <Input
                                  type="text"
                                  size="md"
                                  placeholder="Enter Api Username"
                                  focusBorderColor="primary"
                                  _hover={{ borderColor: "primary" }}
                                  {...register("apiUsername", {
                                    required: true,
                                  })}
                                />
                              </InputGroup>
                            </FormControl>
                          )}
                          {fieldConditions?.isDataSetFieldShow && (
                            <FormControl id="dataset">
                              <FormLabel>
                                Enter your{" "}
                                <Text
                                  display={"inline-block"}
                                  textTransform={"capitalize"}
                                >
                                  {hrms}
                                </Text>{" "}
                                DataSet Key
                              </FormLabel>
                              <InputGroup borderColor="primary">
                                <Input
                                  type="text"
                                  size="md"
                                  placeholder="Enter DataSet Key"
                                  focusBorderColor="primary"
                                  _hover={{ borderColor: "primary" }}
                                  {...register("dataset", {
                                    required: true,
                                  })}
                                />
                              </InputGroup>
                            </FormControl>
                          )}
                          {fieldConditions?.IsUsernamePasswordFieldShow && (
                            <>
                              <FormControl id="username">
                                <FormLabel>
                                  Enter your{" "}
                                  <Text
                                    display={"inline-block"}
                                    textTransform={"capitalize"}
                                  >
                                    {hrms}
                                  </Text>{" "}
                                  username
                                </FormLabel>
                                <InputGroup borderColor="primary">
                                  <Input
                                    type="text"
                                    size="md"
                                    placeholder="Enter Username"
                                    focusBorderColor="primary"
                                    _hover={{ borderColor: "primary" }}
                                    {...register("password", {
                                      required: true,
                                    })}
                                  />
                                </InputGroup>
                              </FormControl>
                              <FormControl id="password">
                                <FormLabel>
                                  Enter your{" "}
                                  <Text
                                    display={"inline-block"}
                                    textTransform={"capitalize"}
                                  >
                                    {hrms}
                                  </Text>{" "}
                                  password
                                </FormLabel>
                                <InputGroup borderColor="primary">
                                  <Input
                                    type="password"
                                    size="md"
                                    placeholder="Enter Password"
                                    focusBorderColor="primary"
                                    _hover={{ borderColor: "primary" }}
                                    {...register("password", {
                                      required: true,
                                    })}
                                  />
                                </InputGroup>
                              </FormControl>
                            </>
                          )}
                          <FormControl id="apikey">
                            {apiKeyHintsShow && <ApiKeyAccordion />}
                            <FormLabel>
                              Enter your{" "}
                              <Text
                                display={"inline-block"}
                                textTransform={"capitalize"}
                              >
                                {hrms}
                              </Text>{" "}
                              API Key
                            </FormLabel>
                            <InputGroup borderColor="primary">
                              <Input
                                type="text"
                                size="md"
                                placeholder="Enter Api Key"
                                focusBorderColor="primary"
                                _hover={{ borderColor: "primary" }}
                                {...register("apiKey", {
                                  required: true,
                                })}
                              />
                            </InputGroup>
                          </FormControl>
                          {fieldConditions?.isClientIdSecretShow && (
                            <>
                              <FormControl id="clientId">
                                <FormLabel>
                                  Enter your{" "}
                                  <Text
                                    display={"inline-block"}
                                    textTransform={"capitalize"}
                                  >
                                    {hrms}
                                  </Text>{" "}
                                  Client ID
                                </FormLabel>
                                <InputGroup borderColor="primary">
                                  <Input
                                    type="text"
                                    size="md"
                                    placeholder="Enter Client ID"
                                    focusBorderColor="primary"
                                    _hover={{ borderColor: "primary" }}
                                    {...register("clientID", {
                                      required: true,
                                    })}
                                  />
                                </InputGroup>
                              </FormControl>
                              <FormControl id="clientSecret">
                                <FormLabel>
                                  Enter your{" "}
                                  <Text
                                    display={"inline-block"}
                                    textTransform={"capitalize"}
                                  >
                                    {hrms}
                                  </Text>{" "}
                                  Client Secret
                                </FormLabel>
                                <InputGroup borderColor="primary">
                                  <Input
                                    type="text"
                                    size="md"
                                    placeholder="Enter Client Secret"
                                    focusBorderColor="primary"
                                    _hover={{ borderColor: "primary" }}
                                    {...register("clientSecret", {
                                      required: true,
                                    })}
                                  />
                                </InputGroup>
                              </FormControl>
                            </>
                          )}
                          <FormControl id="name" float="right">
                            <Button
                              type="submit"
                              variant="solid"
                              bg="primary"
                              color="white"
                              _hover={{}}
                              isLoading={state === "submitting"}
                              isDisabled={state === "submitting"}
                            >
                              Submit
                            </Button>
                          </FormControl>
                        </VStack>
                      </form>
                    </Box>
                  </Box>
                </Box>
              </Flex>
            </Box>
          </Box>
        </Flex>
      </Box>
    </>
  );
};
