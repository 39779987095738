import { Box, Flex, Image } from "@chakra-ui/react";
import successImage from "../../assets/connection.png";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { PageHeaderContent } from "../../components/PageHeader/PageHeaderContent";
export const FormSubmitSuccess = () => {
  return (
    <>
      <Box
        maxW="98%"
        py={{ base: 5, md: 10 }}
        px={{ base: 5, md: 10 }}
        overflow="hidden"
      >
        <PageHeader />
        <Flex
          flexDirection={"column"}
          pt={{ base: 5, md: 7 }}
          w={"full"}
          gap={20}
        >
          <Box justifySelf={"left"}>
            <PageHeaderContent
              headerTitle={`Your details have been submitted ${""}`}
              breakText={"successfully!"}
              headerSubtitle={
                "We’re connecting with your HRMS and notify you once it has successfully been completed."
              }
            />
          </Box>
          <Box
            w={{ base: "full", md: "80%", lg: "35%", "2xl": "45%" }}
            alignSelf={"center"}
          >
            <Image src={successImage} w={"full"} h={"full"} />
          </Box>
        </Flex>
      </Box>
    </>
  );
};
