import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from "@chakra-ui/react";

export const SubdomainAccordion = ({ hrms }) => {
  return (
    <>
      <Accordion allowMultiple>
        <AccordionItem border={"none"} pl={0}>
          <h2>
            <AccordionButton pl={0} _hover={{ bg: "transparent" }}>
              <Box as="span" textAlign="left" color={"gray.500"} mr={1}>
                How do I find my subdomain?
              </Box>
              <AccordionIcon color={"gray.500"} w={6} h={6} />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} pl={0} color={"gray.500"}>
            Enter the subdomain for your {hrms} account below. For example, if
            you sign in at "acme.
            {hrms}.com", enter "acme". Do not enter the full "acme.{hrms}.com".
            Refer to this image for clarification
            {hrms !== "bamboohr" && (
              <Text
                as={"a"}
                color={"blue"}
                href="https://knit-dev-public.s3.ap-south-1.amazonaws.com/ui-auth-component/guide/keka_subdomian.png"
                target="_blank"
                rel="noopener noreferrer"
              >
                - Image Link
              </Text>
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};
